exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-analytics-tsx": () => import("./../../../src/pages/analytics.tsx" /* webpackChunkName: "component---src-pages-analytics-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-reservations-tsx": () => import("./../../../src/pages/reservations.tsx" /* webpackChunkName: "component---src-pages-reservations-tsx" */),
  "component---src-pages-statistics-tsx": () => import("./../../../src/pages/statistics.tsx" /* webpackChunkName: "component---src-pages-statistics-tsx" */),
  "component---src-pages-users-tsx": () => import("./../../../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

